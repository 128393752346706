'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units
 # @description

###
angular
  .module 'mundoAdmin.units', [
    'ui.router'
    'formly'
    'formlyMaterial'
  ]
